export var resolveId = function (obj) {
    if (obj === null || obj === void 0 ? void 0 : obj.id) {
        return obj.id;
    }
    if (obj === null || obj === void 0 ? void 0 : obj._id) {
        console.log('[Wix Blog]', 'No "id" field found', obj);
        return obj._id;
    }
    return undefined;
};
/**
 * Kept for backwards compatibility
 *
 * @deprecated
 */
export var resolveLegacyId = function (obj) { return obj === null || obj === void 0 ? void 0 : obj._id; };
