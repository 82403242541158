import { __assign } from "tslib";
import { keyBy } from 'lodash';
/**
 * Return an array of `_id`, `id` fields
 *
 * @deprecated Remove after post.categoryIds will not contain legacy ids
 */
export var getMixedIds = function (collection) {
    return Object.keys(getMixedIdsMap(collection));
};
/**
 * @deprecated Remove after post.categoryIds will not contain legacy ids
 */
export var getMixedIdsMap = function (collection) {
    var mixedIdsMap = __assign(__assign({}, keyBy(collection, '_id')), keyBy(collection, 'id'));
    delete mixedIdsMap.undefined;
    delete mixedIdsMap.null;
    return mixedIdsMap;
};
